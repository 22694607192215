<template>
    <ModalFull
      :is-visible="previewFileModal"
      @close="$emit('close')"
    >
      <Block title="License File" :streachActions="true">
        <template #action>
          <div
            class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
          >
            <div class="steps flex items-stretch space-x-4"></div>
            <button
              class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
              @click="$emit('close')"
            >
              <XIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <div>
          <div
            v-if="selectedUrl"
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
          >
            <embed
              :src="selectedUrl"
              width="100%"
              style="max-height: 30rem; min-height: 10rem"
            />
          </div>
          <div
            v-else
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
          >
            <embed
              :src="src"
              width="100%"
              style="max-height: 30rem; min-height: 10rem"
            />
          </div>
        </div>
      </Block>
    </ModalFull>
</template>

<script>
import Block from "../../common/Block.vue";
import { XIcon } from "@heroicons/vue/solid";

export default {
    name: "PreviewFileModal",
    components: {
        Block,
        XIcon,
    },
    props: {
      previewFileModal: {
          type: Boolean,
          default: false,
      },
      selectedUrl: {
          type: String,
          default: "",
      },
      src: {
        type: String, 
        default: ""
      }
    },
}
</script>

<style>

</style>
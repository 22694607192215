<template>
  <div
    class="w-full rounded  bg-white px-6 py-5 space-y-8 font-light text-md text-theme-body"
  >
    <div class="flex items-center justify-between">
      <h3 class="flex items-center text-base font-medium text-theme-secondary">
        <Icon name="download" class="w-4 h-4 mr-2 -mt-0.5" />
        <span>Multiple Invoices</span>
      </h3>
      <button
        @click="$emit('close', null)"
        class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
      >
        <Icon name="close" class="w-5 h-5 mr-1" />
      </button>
    </div>

    <div class="flex flex-col space-y-3 items-start">
      <!-- Filters -->
      <div class="w-full flex items-streach gap-5">
        <div class="flex flex-col space-y-2 items-start w-5/12">
          <p class="text-sm font-medium flex-shrink-0">
            Filter by Date Range:
          </p>
          <div class="flex w-full">
            <DatePicker
              class="flex-grow"
              placeholder="Select Dates"
              @calendar-change="handlePanelChange"
              v-model:value="selectedDates"
              format="MMM D, YYYY"
              :disabled-date="disableDatesRange"
              @clear="(selectedDates = null), fetchInvoices()"
              range
            ></DatePicker>
          </div>
        </div>
        <div class="flex flex-col space-y-2 items-start w-5/12">
          <p class="text-sm font-medium flex-shrink-0">
            Search by Order Number:
          </p>
          <div class="flex w-full">
            <div
              class="relative bg-transparent h-10 border border-gray-200 rounded w-full max-w-xs text-gray-400 focus-within:text-gray-600"
            >
              <div
                class="pointer-events-none absolute inset-y-0 left-1 flex items-center"
              >
                <SearchIcon class="h-5 w-5" />
              </div>
              <input
                id="search-field"
                class="block h-full bg-transparent w-full border-transparent py-2.5 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
                v-model="search"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-2 items-start">
          <p class="text-sm font-medium flex-shrink-0 text-transparent">
            Action
          </p>
          <button
            @click="fetchInvoices"
            class="flex-shrink-0 h-10 inline-flex text-xs items-center space-x-1 border  bg-theme-secondary text-white px-4 py-1 rounded leading-none transition-colors duration-300"
          >
            Apply
          </button>
        </div>
      </div>

      <div class="results w-full">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="relative overflow-x-hidden overflow-y-auto max-h-96 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <table
                class="inv-table min-w-full table-fixed divide-y divide-gray-300"
              >
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                      <input
                        type="checkbox"
                        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-theme-primary focus:ring-theme-primary sm:left-6"
                        :checked="
                          indeterminate ||
                            (selectedInvoices.length > 0 &&
                              selectedInvoices.length === invoices.data.length)
                        "
                        :indeterminate="indeterminate"
                        @change="
                          selectedInvoices = $event.target.checked
                            ? invoices.data.map((i) => i.id)
                            : []
                        "
                      />
                    </th>
                    <th
                      scope="col"
                      class=" py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                      width="250"
                    >
                      <span>Order</span>
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody v-if="invoices.loading">
                  <RowLoader />
                </tbody>
                <tbody v-else class="divide-y divide-gray-200 bg-white">
                  <tr v-if="!(invoices ? invoices.data.length > 0 : null)">
                    <td colspan="4" class="text-center">
                      <div class="invoices-empty">
                        <img
                          src="@/assets/images/emptybox.svg"
                          alt="Empty Cart"
                        />
                        <p>Invoices not found.</p>
                      </div>
                    </td>
                  </tr>
                  <template v-else>
                    <tr
                      v-for="inv in invoices.data"
                      :key="inv.id"
                      :class="[
                        selectedInvoices.includes(inv.id) && 'bg-gray-50',
                      ]"
                    >
                      <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                        <div
                          v-if="selectedInvoices.includes(inv.id)"
                          class="absolute inset-y-0 left-0 w-0.5 bg-theme-primary"
                        ></div>
                        <input
                          type="checkbox"
                          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-theme-primary focus:ring-theme-primary sm:left-6"
                          :value="inv.id"
                          v-model="selectedInvoices"
                        />
                      </td>
                      <td
                        :class="[
                          'whitespace-nowrap py-4 pr-3 text-sm font-medium text-left',
                          selectedInvoices.includes(inv.id)
                            ? 'text-theme-primary'
                            : 'text-gray-900',
                        ]"
                      >
                        {{ inv.order_number }}
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left"
                      >
                        {{ inv.created_at }}
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"
                      >
                        ${{ inv.total }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center justify-between">
        <p class="text-left text-xs text-black">
          For invoices before July 10, 2022. Please contact us for assistance.
        </p>
        <button
          @click="downloadInvoices"
          type="button"
          class="flex-shrink-0 h-10 inline-flex text-xs items-center space-x-1 border  bg-theme-secondary text-white px-4 py-1 rounded leading-none transition-colors duration-300 float-right"
          :class="{
            'opacity-50 cursor-not-allowed': !selectedInvoices.length,
          }"
          :disabled="!selectedInvoices.length"
        >
          <Spinner
            v-if="downloading"
            className="w-3 h-3 mr-1"
            :loading="downloading"
          />
          {{ downloading ? "Downloading" : "Download" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";
import { SearchIcon } from "@heroicons/vue/outline";
import DatePicker from "vue-datepicker-next";
import RowLoader from "@/components/loaders/InvoicesRow.vue";
import "./datepicker.scss";

export default {
  name: "DownloadInvoice",
  emits: ["close"],
  components: {
    SearchIcon,
    DatePicker,
    RowLoader,
  },
  setup(props, { emit }) {
    const axios = inject("axios");
    const search = ref("");
    const selectedDates = ref(null);
    const dateFormatter = ref({
      date: "DD MMM YYYY",
      month: "MMM",
    });
    const invoices = ref({
      loading: false,
      data: [],
    });

    const selectedInvoices = ref([]);
    const checked = ref(false);
    const downloading = ref(false);
    const indeterminate = computed(
      () =>
        selectedInvoices.value.length > 0 &&
        selectedInvoices.value.length < invoices.value.data.length
    );

    // Get Invoices from API call onMounted
    onMounted((_) => fetchInvoices());

    const fetchInvoices = () => {
      invoices.value.loading = true;
      axios.authApi
        .get("/invoices", {
          params: {
            q: search.value,
            from_date: selectedDates.value
              ? moment(selectedDates.value[0], "DD MMM YYYY").format(
                  "YYYY-MM-DD"
                )
              : "",
            to_date: selectedDates.value
              ? moment(selectedDates.value[1], "DD MMM YYYY").format(
                  "YYYY-MM-DD"
                )
              : "",
          },
        })
        .then((res) => {
          filterData(res.data.data);
          invoices.value.loading = false;
        });
    };

    const filterData = (data) => {
      if (selectedInvoices.value) {
        invoices.value.data = invoices.value.data.filter((inv) => {
          return selectedInvoices.value.includes(inv.id);
        });
      }

      invoices.value.data.push(...data);

      // Remove duplicates based on the "id" property
      invoices.value.data = [
        ...new Map(invoices.value.data.map((obj) => [obj.id, obj])).values(),
      ];
    };

    const downloadInvoices = () => {
      downloading.value = true;
      axios.authApi
        .post(
          "/bulkInvoices",
          {
            self_receiver: true,
            invoice_ids: selectedInvoices.value,
            company_id: localStorage.getItem("compnay_tg_id"),
            user: localStorage.getItem("user_id"),
          },
          {
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((response) => {
          if (response.data) {
            emit("close", selectedInvoices.value.length);
          }
          downloading.value = false;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const disableDatesRange = (date) => {
      return date < new Date("2022-07-10") || date > new Date();
    };

    const handlePanelChange = ([start, end]) => {
      const startLeftSelectorClass =
        ".mx-calendar-panel-date:first-of-type .mx-calendar-header button[class*='-left']";
      const startRightSelectorClass =
        ".mx-calendar-panel-date:first-of-type .mx-calendar-header button[class*='-right']";

      const endLeftSelectorClass =
        ".mx-calendar-panel-date:last-of-type .mx-calendar-header button[class*='-left']";
      const endRightSelectorClass =
        ".mx-calendar-panel-date:last-of-type .mx-calendar-header button[class*='-right']";

      const [startYearLeft, startMonthLeft] = document.querySelectorAll(
        startLeftSelectorClass
      );
      const [startYearRight, startMonthRight] = document.querySelectorAll(
        startRightSelectorClass
      );

      const [endYearLeft, endMonthLeft] = document.querySelectorAll(
        endLeftSelectorClass
      );
      const [endYearRight, endMonthRight] = document.querySelectorAll(
        endRightSelectorClass
      );

      const minDate = new Date("2022-07-10");
      const maxDate = new Date();

      const fromData = {
        year: start.getFullYear(),
        month: start.getMonth(),
        date: start.getDate(),
      };
      const toData = {
        year: end.getFullYear(),
        month: end.getMonth(),
        date: end.getDate(),
      };
      const minData = {
        year: minDate.getFullYear(),
        month: minDate.getMonth(),
        date: minDate.getDate(),
      };
      const maxData = {
        year: maxDate.getFullYear(),
        month: maxDate.getMonth(),
        date: maxDate.getDate(),
      };

      const classes = ["opacity-0", "pointer-events-none"];

      if (
        start < minDate ||
        (fromData.year <= minData.year && fromData.month <= minData.month)
      ) {
        startYearLeft?.classList.add(...classes);
        startMonthLeft?.classList.add(...classes);
      } else {
        startYearLeft?.classList.remove(...classes);
        startMonthLeft?.classList.remove(...classes);
      }

      if (end >= maxDate) {
        endYearRight?.classList.add(...classes);
        endMonthRight?.classList.add(...classes);
      } else {
        endYearRight?.classList.remove(...classes);
        endMonthRight?.classList.remove(...classes);
      }
    };

    return {
      search,
      selectedDates,
      dateFormatter,
      invoices,
      checked,
      downloadInvoices,
      indeterminate,
      selectedInvoices,
      fetchInvoices,
      disableDatesRange,
      downloading,
      handlePanelChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.inv-table thead {
  @apply sticky top-0 border-b border-gray-100 z-30;
}
.invoices-empty {
  @apply flex flex-col items-center space-y-2 px-4 py-20;
  p {
    @apply text-base text-brand-secondary font-semibold text-center;
  }
  span {
    @apply text-sm text-brand-body w-72 text-center;
    a {
      @apply border-b border-dashed border-brand-body;
    }
  }
}
</style>
